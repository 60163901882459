/*
 * Generic reducer to handle API data fetching
 *
 * Provides attributes to handle 'loading' and 'pristine' states.
 * The actual data is stored in 'data'
 */

// Private stuff
const actionMatches = ( action, actions = []) => actions.some(( acc ) => acc === action );

// Public stuff

export const initialState = {
  loading: false,
  pristine: true,
  data: null,
  meta: null,
  error: null,
};

export const init = ( state = {}) => ({
  ...state,
  loading: true,
});

export const success = ( state = {}, data, meta ) => ({
  ...state,
  data,
  meta,
  pristine: false,
  loading: false,
  error: null,
});

export const failure = ( state = {}, error ) => ({
  ...state,
  error,
  pristine: false,
  loading: false,
});

export const reset = ( state = {}) => ({
  ...state,
  ...initialState,
  pristine: true, // Not sure about this
});

export default ({
  initActions, successActions, failureActions, resetActions,
}) => (
  state = initialState,
  { type, payload },
) => {
  if ( !type ) return state;

  if ( actionMatches( type, initActions )) {
    return init( state );
  }
  if ( actionMatches( type, successActions )) {
    return success( state, payload.data, payload.meta );
  }
  if ( actionMatches( type, failureActions )) {
    return failure( state, payload );
  }
  if ( actionMatches( type, resetActions )) {
    return reset( state );
  }

  return state;
};
